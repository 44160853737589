<template>
  <div class="box">
    <!-- <div class="top" @click="toVideo"></div> -->
    <!-- <video-player
      class="video-player vjs-custom-skin top"
      :playsinline="true"
      :options="options"
      v-if="isPlayer"
    >
    </video-player> -->
    <video
      id="hlsVideo"
      ref="hlsVideo"
      controls
      preload="true"
      class="top"
    ></video>

    <div class="course">
      <div v-for="l in info.list" :key="l.courseContentId">
        <img
          :src="l.contentImg"
          class="course-img"
          @click="play(l.courseContentId, l.courseContentPath)"
        />
        <img
          src="@/img/course/lock.png"
          class="lock"
          v-if="l.isLimitVip && userInfo.vip != 1"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Hls from "hls.js";
import url from "@/service/url-config";
import "videojs-contrib-hls";
export default {
  data() {
    return {
      hls: "",
      info: {},
      userInfo: {},
      isPlayer: false, //是否就绪
      options: {
        live: false,
        preload: "auto",
        autoplay: false,
        language: "zh-CN",
        aspectRatio: "16:8",
        fluid: true,
        sources: [
          {
            type: "application/x-mpegURL",
            src: "", //url地址
          },
        ],
        notSupportedMessage: "此视频暂无法播放，请稍后再试", //允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: false,
          durationDisplay: false,
          remainingTimeDisplay: false,
          fullscreenToggle: true, //全屏按钮
        },
        flash: {
          hls: {
            withCredentials: false,
          },
        },
      },
    };
  },
  created() {
    this.$http.post(url.getCourseDetail + "?courseId=1").then((res) => {
      this.info = res.data;
      let courseContentId = this.$route.query.courseContentId
        ? this.$route.query.courseContentId
        : res.data.list[0].courseContentId;
      let courseContentPath = this.$route.query.courseContentPath
        ? this.$route.query.courseContentPath
        : res.data.list[0].courseContentPath;
      this.play(courseContentId, courseContentPath);
    });
    //获取用户信息
    this.$http.get(url.userInfo).then((res) => {
      this.userInfo = res.data;
    });

    let _this = this;
    _this.$once("hook:beforeDestroy", () => {
      _this.destroyHls();
    });
  },
  methods: {
    play(courseContentId, courseContentPath) {
      let url = `https://api.weiqilile.com/snsapi/v1/course/video/${courseContentId}/m3u8?courseContentPath=${courseContentPath}`;
      this.options.sources[0].src = url;
      this.isPlayer = true;
      this.loadVideoFn(url);
    },

    destroyHls: function () {
      if (this.hls) {
        this.$refs.hlsVideo.pause();
        this.hls.destroy();
        this.hls = null;
      }
    },
    loadVideoFn: function (url) {
      if (Hls.isSupported()) {
        this.hls = new Hls();
        this.hls.loadSource(url); // CCTV1直播源
        this.hls.attachMedia(this.$refs.hlsVideo);
        this.hls.on(Hls.Events.MANIFEST_PARSED, () => {
          console.log("加载成功");
          this.$refs.hlsVideo.play();
        });
        this.hls.on(Hls.Events.ERROR, (event, data) => {
          console.log(event, data);
          // 监听出错事件
          console.log("加载失败");
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.box {
  background: #ffffff;
  padding-top: 66px;
  padding-bottom: 50px;
  .top {
    width: 375px;
    height: 205px;
  }
  .course {
    width: 100%;
    margin: 0 auto;
    overflow-x: scroll;
    display: flex;
    div {
      width: 153px;
      height: 95px;
      margin-left: 8px;
      border-radius: 10px;
      background: lightblue;
      margin-top: 10px;
      display: block;
      position: relative;
      .course-img {
        width: 153px;
        height: 95px;
        border-radius: 10px;
      }
      .lock {
        width: 153px;
        height: 95px;
        border-radius: 10px;
        position: absolute;
        left: 0;
        top: 0;
      }
    }
  }
}
</style>
